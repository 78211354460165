<script>
import Layout from "../../layouts/main";
import YearLayout from '../../layouts/yearLayout';
import ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import moment from 'moment';
import errorHandler from '@/helpers/errorHandler'
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page:{
    title: 'Stage sessions'
  },
  head() {
    return {
      title: `Stage-Videos`,
    };
  },
  components:{
    Layout,
    YearLayout,
    ConfirmActionDialog,
    ExportExcel
  }, 
  async created(){
    await this.$store.dispatch('year/getYearInfo',this.$route.params.year).then(()=>{
        this.year = this.$store.getters['year/getYear'];
    }).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    })
    await this.$store.dispatch('video/getAllYoutubeVideos',this.$route.params.year).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    })
  },
  data() {
    return {
    year: null,
    perPage: 5,
    currentPage: 1,
    pageOptions: [5, 10, 15],
    filter: "",
    sortBy: "name",
    sortDesc: false,
    filterOn: ["name","chapter","lesson"],
    fields: [
      {
        key: "name",
        label: "Name",
        sortable: true,
        selected: true
      },
      {
        key: "chapter",
        label: "Chapter name",
        sortable: true,
        selected: true
      },
      {
        key: "lesson",
        label: "Lesson name",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
    };
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("video/deleteYoutubeVideo",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
      this.$bvModal.show('deleteRecord')
      this.recordId = id
    },
    viewProfile(record,index){
      this.$router.push('/videos/' + record.id + '/details')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    videos() {
      return this.$store.getters['video/allVideos'];
    },
    rows() {
      return this.videos.length;
    }
  },
};
</script>

<template>
  <Layout>
    <YearLayout :year="this.year" v-if="year">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                <div class="card-body">
                    <div class="row mb-2">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Display&nbsp;
                            <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            ></b-form-select
                            >&nbsp;Records
                        </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                        >
                        <router-link to="/youtube-videos/create">
                          <b-button variant="success" class="btn-sm mr-2">
                            <i class="mdi mdi-plus"></i>
                            Add video
                          </b-button>
                        </router-link>
                        <export-excel :jsonData="videos" v-if="$store.getters['user/role'] == 0"></export-excel>
                        <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                            ></b-form-input>
                        </label>
                        <b-dropdown
                            id="dropdown-offset"
                            offset="250"
                            variant="link"
                            class="btn-icon-only"
                        >
                            <template #button-content>
                            <i class="fas fa-th-large iconGray"></i>
                            </template>
                            <b-dropdown-form>
                            <b-form-checkbox
                                v-for="(field, index) in fields"
                                :key="index"
                                class="mb-3"
                                v-model="field.selected"
                                :disabled="field.disabled"
                                >{{ field.label }}</b-form-checkbox
                            >
                            </b-dropdown-form>
                        </b-dropdown>
                        </div>
                    </div>
                    <!-- End search -->
                    </div>
                    <b-table
                    table-class="table table-centered w-100 table-hover"
                    thead-tr-class="bg-light"
                    tbody-tr-class="clickableRow"
                    :items="videos"
                    :fields="tableFields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    
                    >
                    <template v-slot:cell(chapter)="data">
                        {{ data.item.chapter.number + ' - ' + data.item.chapter.description }}
                    </template>
                    <template v-slot:cell(lesson)="data">
                        {{ data.item.lesson.number + ' - ' + data.item.lesson.description }}
                    </template>
                    <template v-slot:cell(lock)="data">
                        <i class="mdi mdi-lock" v-if="data.item.lock"></i>
                        <!-- TODO:: edit here the icon for unlocked videos -->
                        <i class="mdi mdi-unlocked-variant" v-else></i>
                    </template>
                    <template v-slot:cell(timestamp)="data">
                        {{ data.item.timestamp | formatDate }}
                    </template>
                <template v-slot:cell(action)="data">
                        <b-dropdown
                        class="btn-icon-onl"
                        right
                        toggle-class="arrow-none btn-light btn-sm"
                        >
                        <template v-slot:button-content>
                            <i class="mdi mdi-dots-horizontal iconGray"></i>
                        </template>

                        <b-dropdown-item
                            :to="'/youtube-videos/' + data.item.id + '/edit'"
                        >
                            <i
                            class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                            ></i
                            >Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                        v-on:click="showDeleteModal(data.item.id)"
                        ><i
                            class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                        ></i
                        >Delete
                        </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    </b-table>
                    <div class="row">
                    <div class="col">
                        <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                        >
                        <ul class="pagination pagination-rounded">
                            <!-- pagination -->
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="(filter == '' )?rows:totalRows"
                            :per-page="perPage"
                            ></b-pagination>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <b-modal id="deleteRecord" size="md" title="Warning Action">
                <ConfirmActionDialog/>
                <template #modal-footer>
                <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
                </template>
            </b-modal>
    </YearLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
